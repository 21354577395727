import axios from "axios";
import React, { useState, useLayoutEffect, useContext, useEffect } from "react";
import createNotification from "../components/common/reactNotification";
import { getAuthAndData, postAuthAndData } from "../api/ApiHelper";
import { ENGT_PROD_HOST_URI, VALIDATE_TOKEN_API } from "../api/apiConstants";
import { useHistory } from "react-router-dom";
import { dispatchLoaderData } from "../actions/route/routeActionHandler";
import { useDispatch } from "react-redux";

const AppContext = React.createContext();

// export default AppContext;

export function AppProvider(props) {
  const [isAuthorisedFacebook, setIsAuthorisedFacebook] = useState(false);
  const [isAuthorisedInstagram, setIsAuthorisedInstagram] = useState(false);
  const [isAuthorisedYT, setIsAuthorisedYT] = useState(false);

  const [facebookData, setFacebookData] = useState();
  const [instagramData, setInstagramData] = useState();
  const [ytData, setYTData] = useState();

  const [isInstaSelected, setIsInstaSelected] = useState(false);
  const [isYoutubeSelected, setIsYoutubeSelected] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleLoader = (showBool) => {
    dispatch(dispatchLoaderData(showBool));
  };

  const handleResponseCode = (res, platform) => {
    if (res.code === 1) {
      if (platform === 0) {
        setIsAuthorisedFacebook(true);
        setFacebookData(res.data);
      }
      if (platform === 1) {
        setIsAuthorisedInstagram(true);
        setInstagramData(res.data);
      }
      if (platform === 2) {
        setIsAuthorisedYT(true);
        setYTData(res.data);
      }
    } else {
      if (platform === 0) {
        setIsAuthorisedFacebook(false);
      }
      if (platform === 1) {
        setIsAuthorisedInstagram(false);
      }
      if (platform === 2) {
        setIsAuthorisedYT(false);
      }
    }
  };

  function validateToken(platform) {
    getAuthAndData(
      `${ENGT_PROD_HOST_URI}${VALIDATE_TOKEN_API}?sm_platform=${platform}`
    )
      .then((res) => {
        handleResponseCode(res, platform);
      })
      .catch((err) => {
        console.error("validateToken error", err);
      });
  }

  useEffect(() => {
    handleLoader(true);
    validateToken(0);
    validateToken(1);
    validateToken(2);

    setTimeout(() => handleLoader(false), 1500);
  }, []);

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const code = urlParams.get("code");

  //   if (code === null) return;

  //   postAuthAndData(
  //     `https://5yiek6g5g0.execute-api.ap-south-1.amazonaws.com/Prod/api/engt/GenerateYouTubeAccessToken`,
  //     code,
  //     history
  //   )
  //     .then((response) => {
  //       // if (handleResponseCode(response)) {
  //       console.log("addaccountresponse", response);
  //       // createNotification("success","Code generated successfully")
  //       console.log("handleInstaAuthorize response.data", response.url);
  //       // activationCodeUrl = response.url;
  //       // window.location.href = activationCodeUrl;
  //       handleLoader(false);
  //       //  window.open(activationCodeUrl); //! for different window
  //       // }
  //     })
  //     .catch((error) => {
  //       console.error("error from handleInstaAuthorize", error);
  //       createNotification("error", "Error occured during authorization");
  //       handleLoader(false);
  //     });
  // }, []);

  //drag and drop code start

  const [droppedVideos, setDroppedVideos] = useState([]);
  const [droppedImages, setDroppedImages] = useState([]);
  const [contentDropped, setContentDropped] = useState(false);

  const handleVideoDragStart = (e, video) => {
    e.dataTransfer.setData("video", video);
  };
  const handleImageDragStart = (e, image) => {
    e.dataTransfer.setData("image", image);
  };

  // const handleVideoDrop = (video) => {
  //   setDroppedVideos([...droppedVideos, video]);
  // };

  const handleVideoDrop = (video) => {
    if (contentDropped) {
      createNotification("error", "Only one video is allowed to be dropped");
    } else {
      setDroppedVideos([...droppedVideos, video]);
      setContentDropped(true);
    }
  };

  const handleImageDrop = (image) => {
    if (contentDropped) {
      createNotification("error", "Only one Image is allowed to be dropped");
    } else {
      setDroppedImages([...droppedImages, image]);
      setContentDropped(true);
    }
  };

  console.log("droppedVideos", droppedVideos);
  console.log("droppedImages", droppedImages);

  return (
    <AppContext.Provider
      value={{
        isAuthorisedFacebook,
        isAuthorisedInstagram,
        setIsAuthorisedFacebook,
        setIsAuthorisedInstagram,
        isAuthorisedYT,
        setIsAuthorisedYT,
        facebookData,
        setFacebookData,
        instagramData,
        setInstagramData,
        ytData,
        setYTData,
        handleVideoDragStart,
        handleImageDragStart,
        handleVideoDrop,
        handleImageDrop,
        droppedVideos,
        setDroppedVideos,
        droppedImages,
        setDroppedImages,
        setContentDropped,
        isInstaSelected,
        setIsInstaSelected,
        isYoutubeSelected,
        setIsYoutubeSelected,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}

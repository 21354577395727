//export const IDTY_PROD_HOST_URI = "http://localhost:807/api";
//export const ENGT_PROD_BASE_URI = "http://localhost:815/api";
//export const REPT_PROD_HOST_URI = "http://localhost:818/api";
// export const TENT_PROD_HOST_URI = 'http://localhost:880/api';

export const IDTY_PROD_HOST_URI =
  "https://frg6g6wml9.execute-api.ap-south-1.amazonaws.com/Prod/api";
export const ENGT_PROD_BASE_URI =
  "https://5yiek6g5g0.execute-api.ap-south-1.amazonaws.com/Prod/api";
export const REPT_PROD_HOST_URI =
  "https://wehmutx1th.execute-api.ap-south-1.amazonaws.com/Prod/api";
export const TENT_PROD_HOST_URI =
  "https://i5dwhd96xi.execute-api.ap-south-1.amazonaws.com/Prod/api";
export const EVNT_PROD_HOST_URI =
  //"https://q261x21b15.execute-api.ap-south-1.amazonaws.com/Prod/api";
  "https://wisrg31wp2.execute-api.ap-south-1.amazonaws.com/Prod/api";

export const ENGT_PROD_HOST_URI =
  // "https://fxojmluid9.execute-api.ap-south-1.amazonaws.com/Prod/api";
  "https://5yiek6g5g0.execute-api.ap-south-1.amazonaws.com/Prod/api";

export const ENGT_PROD_HOST_URI_IG =
  "https://16hg83ahyk.execute-api.ap-south-1.amazonaws.com/prod/socialServiceEngine";

// export const CLIENT_URL_FOR_FB_IG =
//   "https://www.facebook.com/v17.0/dialog/oauth?client_id=1253790345314741&client_secret=402f2959f2f86aa80961f4074db57e3b&redirect_uri=https://live.blaash.io/addaccount/&scope=business_management,instagram_content_publish,pages_manage_posts,pages_manage_engagement,pages_show_list&response_type=code";

export const CLIENT_URL_FOR_FB_IG =
  "https://www.facebook.com/v17.0/dialog/oauth?client_id=819594166625881&client_secret=d8c3d2947c8bb438f567d37c3c15030c&redirect_uri=https://live.blaash.io/addaccount/&scope=instagram_basic,business_management,instagram_content_publish,pages_manage_posts,pages_manage_engagement,pages_show_list&response_type=code";
//"https://www.facebook.com/v17.0/dialog/oauth?client_id=819594166625881&client_secret=d8c3d2947c8bb438f567d37c3c15030c&redirect_uri=https://live.blaash.io/addaccount/&scope=instagram_basic,business_management,instagram_content_publish,pages_manage_posts,pages_manage_engagement,pages_show_list,user_profile,user_media&response_type=code";

export const CLIENT_URL_FOR_YT =
  "https://accounts.google.com/o/oauth2/v2/auth?client_id=511035235678-4cnlhassu9tsh0a6ev1j3oo3a3k46vmj.apps.googleusercontent.com&redirect_uri=https://live.blaash.io/addaccount&scope=https://www.googleapis.com/auth/youtube.readonly&access_type=offline&include_granted_scopes=true&response_type=code&prompt=select_account consent";

export const GET_MEDIA = "/getMediaData";
export const GET_INSTA_MEDIA = "/engt/GetInstagramMedia";
export const ADD_ACCOUNT = "/addAccount";
export const ACTIVATE_CODE = "/activateCode";

export const SOMETHING_WENT_WRONG = "Something went wrong";

export const serverResponse = {
  SUCCESS: 1,
  USER_EMAIL_NOT_FOUND: 2,
  INVALID_TOKEN: 3,
  USER_ALREADY_EXISTS: 4,
};

export const GET_BROADCAST_URL_BY_CODE =
  "/engt/getMultiHostChannelPublishSettings";

export const EMAIL = "Email";
export const DEFAULT_FILTER_DAYS = 7;
export const DEFAULT_FILTER_MONTHS = 2;
export const JWT_TOKEN = "JwtToken";
export const REFRESH_TOKEN = "RefreshToken";
export const DUMM_TENANT_KEY = "BLAASH-SYS-2021";

export const USER_BY_FILTERS = "/idty/userbyfilter?pagesize=";
export const USER_BY_MAIL = "/idty/isemailexists?email=";
export const INVITE_USER = "/idty/admin/inviteuser";
export const UPDATE_USER = "/idty/updateuser";
export const DELETE_USER = "/idty/deleteuser?user_id=";
export const GROUP_ADDEDIT = "/idty/groupaddedit";
export const GROUP_ALL = "/idty/group/all";
export const USER_DATA_GROUP_PERMISSIONS =
  "/idty/userDataAndGroupPermissions?email=";
export const PERMISSION_ALL = "/idty/permission/all";
export const PERMISSION_BY_GROUP = "/idty/permissionsbygroup?group_id=";
export const ADD_USER_TO_GROUP = "/idty/addusertogroup";
export const REMOVE_USER_TO_GROUP = "/idty/removeusertogroup";
export const USER_BY_USERNAME = "/idty/userbyusername?user_name=";
export const GET_GROUPS = "/idty/getgroups";
export const ADD_NEWGROUP = "/idty/addnewgroup";
export const UPDATE_GROUP = "/idty/updategroup";
export const DELETE_GROUP = "/idty/deletegroup?group_id=";
export const GROUP_BY_GROUPNAME = "/idty/groupbygroupname?group_name=";

export const ENGAGEMENTS_BY_FILTERS = "/engt/engagementbyfilters";
export const CUSTOMERS_BY_FILTERS = "/engt/customersbyfilters";
export const REWARD_BY_FILTERS = "/engt/rewardbyfilters";
export const REWARD_TYPES = "/engt/rewardtypes";
export const REWARDS_BY_REWARD_TYPE =
  "/engt/rewardByRewardType?reward_type_id=";
export const JOURNEY_BY_FILTERS = "/engt/journeybyfilters";
export const SAVE_ENGAGEMENT = "/engt/saveengagement";
export const DELETE_ENGAGEMENT = "/engt/deleteengagement?engagement_id=";
export const UPDATE_ENGAGEMENT_STATUS = "/engt/updateengagementstatus";
export const ENGAGEMENTS_BY_STATUS = "/engt/engagementsbystatus";
export const ENGAGEMENTS_BY_ID = "/engt/engagementbyid?engagement_id=";
export const ENGAGEMENTS_DETAILS_BY_ID =
  "/engt/engagementdetailsbyid?engagement_id=";
export const ENGAGEMENT_UPDATE_STATUS =
  "/engt/updateengagementstatus?engagement_id=";
export const ENGAGEMENT_BY_STATUS_ID =
  "/engt/engagementsbystatus?engagement_status_id=";
export const JOURNEYS = "/engt/AllJourneys";
export const JOURNEY_TASKS = "/engt/journeyTasks";
export const ADD_JOURNEY_DETAILS = "/engt/addjourneydetails";
export const UPDATE_JOURNEY_DETAILS = "/engt/updatejourneydetails";
export const DELETE_JOURNEY_DETAILS = "/engt/deletejourney?journey_id=";
export const JOURNEYS_BY_SEARCH = "/engt/JourneysByName?journey_name=";
export const MASTER_REWARDS = "/engt/masterRewards";
export const CREATE_REWARD_MASTER = "/engt/CreateRewardMaster";
export const UPDATE_REWARD_MATER = "/engt/updateRewardMaster";
export const MASTER_CATEGORIES = "/engt/masterCategories";
export const UPDATE_REWARD_MASTER_STATUS_BY_ID =
  "/engt/updateRewardStatus?reward_master_id=";
export const INFLUENCERS_LIST = "/engt/getfeeds";
export const GET_FEEDS_V1 = "/engt/getfeeds_v1";
export const INFLUENCERS_BY_FILTER = "/engt/interestedinfluencers";
export const SIMILAR_INFLUENCERS = "/engt/similarinfluencer?influencerid=";
export const INFLUENCER_DETAIL = "/engt/influencer?influencerid=";
export const BOOK_INFLUENCER = "/engt/bookinfluencer";
export const FILTER_MASTER = "/engt/getfiltermaster";
export const SAVE_CUSTOMER_SEGMENTS = "/engt/save-segment";
export const EDIT_CUSTOMER_SEGMENTS = "/engt/getsegmentsbyid?segment_id=";
export const SET_ALL_SEGMENT_DATA = "/engt/get-all-segments";
export const BRAND_PARTNER = "/engt/brandPartner";
export const SAVE_BRAND_PARTNER = "/engt/saveBrandPartners";
export const ENGAGEMENT_AB_TESTING = "/engt/engagementWithABEngagements";
export const PREE_SIGNED_URL = "/engt/preSignedUrl";
export const SAVE_POST_DATA = "/engt/savePostData";
export const PREDICTION_MASTER_DATA = "/engt/getPredictionMasterData";
export const DELETE_SEGMENT_BY_ID =
  "/engt/delete-segment-id?segment_customers_id=";
export const GET_SOCIAL_DASHBOARD = "/engt/getSocialDashboard";

export const CONSOLIDATION_SUMMARY_BY_FILTER =
  "/rept/consolidationSummaryByFilter";
export const DAY_WISE_SALES_BY_FILTER = "/rept/dayWiseSalesByFilter";
export const DAY_WISE_ACTIVE_ENGAGED_USERS =
  "/rept/dayWiseActiveAndEngagedUsers";
export const MONTH_WISE_ACTIVE_ENGAGED_USERS =
  "/rept/monthWiseActiveAndEngagedUsers";
export const CONSOLIDATED_BRAND_HEALTH = "/rept/conslidatedBrandHealth";
export const CONSOLIDATED_INCREMENTAL_SALES =
  "/rept/consolidatedIncrementalSales";
export const DAY_WISE_BRAND_HEALTH_DATA = "/rept/dayWiseBrandHealthData";
export const ENGAGEMENT_STATISTICS = "/rept/engagementStatistics";

export const SETTINGS_TEMPLATE = "/tenm/settingsTemplate";
export const GET_TENANT_SETTINGS = "/tenm/getTenantSettings";
export const SAVE_TENANT_SETTINGS = "/tenm/saveTenantSettings";
export const GET_PREDICTION_DETAILS =
  "/engt/getPredictionDetails?engagementId=";
export const UPDATE_PREDICTION_RESULTS = "/engt/UpdatePredictionResults";
export const ACTIVE_PREDICTIONS = "/engt/getActivePredictions";
export const EVENT_LIST_FOR_LAST_X_HOURS = "/rept/eventListForLastXHours";
export const ACTIVE_CUSTOMER_COUNT_FOR_LAST_X_HOURS =
  "/rept/activeCustomerCountForLastXHours";

export const TOP_ENGAGEMENTS_CUSTOMER_COUNT =
  "/rept/topEngagementsCustomerCount";
export const CART_GROSS_VALUE = "/rept/cartGrossValue";
export const STAT_CHANGE = "/rept/statChange";
export const VIDEO_DURATION_GROUP_BY_DAY = "/rept/videoDurationGroupByDay";
export const GET_PRODUCT_DETAILS = "/evnt/getProductdetails";
export const GET_ALL_PRODUCT = "/evnt/getallProducts";
export const STOP_STREAM = "/engt/stopStream";
export const ATTACH_COUPON = "/engt/attachcoupon";
export const GET_LIVE_DETAIL_BY_SCHEDULE_ID =
  "/engt/getLiveDetailsByScheduleId?sid=";
export const GET_ATTACHED_COUPON = "/engt/getAttchedCoupon?eid=";
export const CREATE_PLAY_LIST = "/engt/createPlayList";
export const GET_ALL_PLAYLIST = "/engt/getAllPlayList";
export const SAVE_EMBEDDED_PLAYYER_DETAILS = "/engt/saveEmbeddedPlayerdetails";
export const GET_ALL_EMBEDDED_PLAYER_DETAILS =
  "/engt/getAllEmbeddedPlayerdetails";
export const TRANSFORM_FILES = "/engt/transformFiles";
export const GET_POST_PRODUCT = "/engt/getPostProducts?eid=";
export const GET_POST_CONTENT = "/engt/getPostContent?eid=";
export const GET_STORY_DASHBOARD = "/engt/getStoryDashboard";
export const GET_REVENUE_OVERVIEW = "/engt/getRevenueOverview";
export const GET_REVENUE_TREND_OVERVIEW = "/engt/getRevenueTrendOverview";
export const GET_REVENUE_SALES_OVERVIEW = "/engt/getRevenueSalesOverview";
export const PROCESS_AND_SAVE_BASEURL_TO_AWS =
  "/engt/ProcessAndSaveBaseUrlToAWS";
export const VALIDATE_TOKEN_API = "/engt/ValidateSMToken";
export const GET_MEDIA_ASSETS = "/engt/GetAsset";
export const GENERATE_ACCESS_TOKEN = "/engt/GenearteLongLivedAccessToken";
export const GET_INSTAGRAM_CONTAINER = "/engt/ProcessReelsToInstagram";
export const UPLOAD_REELS_TO_INSTAGRAM = "/engt/UploadReelsToInstagram";
export const UPLOAD_POST_TO_FACEBOOK = "/engt/ProcessAndUploadPostToFacebook";
export const GENERATE_YOUTUBE_ACCESS_TOKEN = "/engt/GenerateYouTubeAccessToken";
export const GET_SHORTS_FROM_YOUTUBE = "/engt/GetShortsFromYoutube";
export const GET_YOUTUBE_DATA = "/engt/GetYoutubeVideoDataURL";
export const SAVE_TO_CLOUDINARY = "/engt/SaveBaseUrlToCloudinary";
export const SAVE_TO_AWS = "/engt/SaveToAWS";
export const SAVE_MEDIA_DETAILS = "/engt/saveMediaDetails";
export const GENERATE_AI_TEXT = "/engt/generateText";
export const GET_ALL_TEMPLATES = "/engt/GetAllTemplatesByContentType";
export const GET_TEMPLATES_BY_NAME = "/engt/GetTemplateByName";
export const GET_FONTS = "/engt/GetFonts";
export const GET_PRODUCT_IMAGES = "/evnt/GetProductImages";
export const GET_PIXELBAY_IMAGE = "/engt/GetImageFromPixelApi";
export const GET_PIXELBAY_VIDEO = "/engt/GetVideoFromPixelApi";
export const GET_IMAGE_OPENAI = "/engt/GetImageFromOpenAI";
export const GET_OLD_IMAGE_OPENAI = "/engt/GetLastGeneratedMedia";
export const GET_MEDIA_BY_TYPE = "/engt/GetMediaFilesByType";
export const GET_USER_BALANCE = "/engt/GetCreditBalanceByTenantId";
export const PAGE_PERMISSION = {
  manage_live_view: 1,
  manage_engagement: 2,
  manage_journey: 4,
  manage_ab_testing: 5,
  manage_prediction: 7,
  manage_hire_influencers: 9,
  manage_studio: 10,
  manage_dashboard: 11,
  manage_customer_segment: 12,
  manage_rewards: 13,
  manage_reward_partner: 14,
  manage_video_performance: 15,
};

import React, { useEffect } from "react";
import "./template.css";
import AudiotrackRoundedIcon from "@material-ui/icons/AudiotrackRounded";
import PauseCircleFilledRoundedIcon from "@material-ui/icons/PauseCircleFilledRounded";
import { Typography } from "@material-ui/core";
import { useAppContext } from "../../../../../../contexts/appContext";

const TemplateCategory = ({
  playlistgroup,
  type,
  audioPlayPause,
  indexParent,
  isPlaying,
  indexAudio,
  indexCategory,
  expandIndex,
}) => {
  const { handleVideoDragStart, handleImageDragStart } = useAppContext();

  return (
    <div
      key={indexParent}
      style={{
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        marginTop: "10px",
      }}
    >
      <div
        className={`image-store${
          expandIndex === indexParent ? " expanded" : ""
        }`}
      >
        {playlistgroup.map((item, index) => (
          <div
            key={index}
            className={
              type === 11
                ? "singleLayout"
                : `templatecontainer${
                    type === 3 ||
                    type === 5 ||
                    type === 6 ||
                    type === 9 ||
                    type === 10 ||
                    type === 12
                      ? " bigLayout"
                      : ""
                  }`
            }
          >
            {(type === 1 || type === 4) && (
              <img
                key={index}
                id={index}
                draggable={true}
                onDragStart={(e) => handleImageDragStart(e, item.url)}
                className="card-template"
                alt={item.name}
                src={item.url}
              />
            )}
            {type === 2 && (
              <video
                controls
                id={index}
                key={index}
                draggable={true}
                onDragStart={(e) => handleVideoDragStart(e, item.url)}
                className="card-template"
                src={item.url}
              />
            )}
            {type === 3 && (
              <div
                className="audioControl"
                id={index}
                key={index}
                draggable={true}
                // onDragStart={(e) => handleVideoDragStart(e, item.url)}
                onClick={() => audioPlayPause(indexParent, index)}
                // style={{
                //   width: "84px",
                //   height: "128.348px",
                //   color: "white",
                //   display: "flex",
                //   flexDirection: "column",
                //   justifyContent: "center",
                //   textAlign: "center",
                // }}
              >
                <audio
                  id={`audiotemplate${indexParent}_${index}`}
                  src={item.url}
                  key={index}
                />
                {isPlaying &&
                index === indexAudio &&
                indexParent === indexCategory ? (
                  <PauseCircleFilledRoundedIcon
                    style={{
                      fontSize: "60px",
                    }}
                  />
                ) : (
                  <AudiotrackRoundedIcon
                    style={{
                      fontSize: "60px",
                    }}
                  />
                )}
                <div
                  style={{
                    height: "40px",
                  }}
                >
                  <Typography style={{ fontSize: "14px", lineHeight: "21px" }}>
                    {item.name}
                  </Typography>
                </div>
              </div>
            )}
            {type === 5 && (
              <div
                id={index}
                key={index}
                draggable={true}
                // onDragStart={(e) => handleVideoDragStart(e, item.font_id)}
                className="typography-container"
              >
                <p
                  className="typography-card"
                  style={{
                    fontFamily: item.font_id,
                    color: item.font_color,
                  }}
                >
                  {item.font_text}
                </p>
              </div>
            )}
            {type === 6 && (
              <img
                key={index}
                id={index}
                draggable={true}
                onDragStart={(e) => handleImageDragStart(e, item.base_url)}
                className="card-template"
                alt={item.product_name}
                src={item.base_url}
              />
            )}
            {type === 7 && (
              <img
                key={index}
                id={index}
                draggable={true}
                onDragStart={(e) => handleImageDragStart(e, item.src.tiny)}
                className="card-template"
                alt={item.alt}
                src={item.src.tiny}
              />
            )}
            {type === 8 && (
              <video
                id={index}
                controls
                key={index}
                draggable={true}
                onDragStart={(e) =>
                  handleVideoDragStart(e, item.video_files?.[0].link)
                }
                className="card-template"
                src={item.video_files?.[0].link}
              />
            )}
            {type === 9 && (
              <img
                key={index}
                id={index}
                draggable={true}
                onDragStart={(e) => handleImageDragStart(e, item.url)}
                className="card-template"
                alt={item.name}
                src={item.url}
              />
            )}
            {type === 10 && (
              <video
                id={index}
                controls
                key={index}
                draggable={true}
                onDragStart={(e) => handleVideoDragStart(e, item.url)}
                className="card-template"
                src={item.url}
              />
            )}
            {type === 11 && (
              <img
                key={index}
                id={index}
                draggable={true}
                onDragStart={(e) => handleImageDragStart(e, item.url)}
                className="card-template"
                alt="AI Generated Image"
                src={item.url}
              />
            )}
            {type === 12 && (
              <img
                key={index}
                id={index}
                draggable={true}
                onDragStart={(e) => handleImageDragStart(e, item.url)}
                className="card-template"
                alt="AI Generated Image"
                src={item.url}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TemplateCategory;
